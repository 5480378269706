// calls all apis for ya

import Axios from 'axios';
import { apiDruidBaseUrl, apiBaseUrl, CONTEXT, apiFileUploadBaseUrl } from '../constants/configuration';

// const TYPE_JSON = 'application/json';
const TYPE_JSON = 'application/json;charset=UTF-8';

export const buildAPIInstance = ({ noCors = false, druid = false, multipart = false, contenttype = TYPE_JSON }) => {
  // wait for valid base url
  const objAxios = Axios.create({
    baseURL: multipart ? apiFileUploadBaseUrl : druid ? apiDruidBaseUrl : apiBaseUrl,
    // baseURL: apiBaseUrl,
    mode: druid ? 'no-cors' : '',
    headers: {
      domain: window.location.origin,
      // domain:"https://testbenchv2.torcai.com",
      // domain: 'https://platform.torcai.com',
      // domain:"https://testbench.torcai.com",
      // domain: "https://stageplatform.torcai.com",
      common: {
        Accept: TYPE_JSON,
        'Content-Type': contenttype
      },
      post: {
        'Content-Type': TYPE_JSON
      }
    },
    transformResponse: [function (data) {
      //Do whatever you want to transform the data
      if (data) {
        try {
          const newdata = JSON.parse(data);
          // console.log(newdata);
          // debugger;
          if (newdata ? (newdata.responseCode && (newdata.responseCode.desc === 'Not authorised' || newdata.responseCode.msg === 'Invalid User Token')) || (newdata.responeCode && (newdata.responeCode.desc === 'Not authorised' || newdata.responeCode.msg === 'Invalid User Token')) : (newdata.responeCode && newdata.responeCode.msg === 'Invalid User Token')) {
            localStorage.clear();
            window.location.href = `${CONTEXT}`;
            throw new Error('token expired');
          }
        } catch (err) {
          return data;
        }
      }
      return JSON.parse(data);
    }],


  });

  return objAxios;
};

const client = (params = {}) => buildAPIInstance(params);

export default client;